import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["operationRegion", "pickupLocation"];

  toggleOperationRegion(event) {
    const operationRegionId = event.target.value;
    const isChecked = event.target.checked;

    const relatedPickupLocations = this.pickupLocationTargets.filter((checkbox) => checkbox.dataset.operationRegionId === operationRegionId);

    relatedPickupLocations.forEach((checkbox) => {
      if (!isChecked) {
        checkbox.checked = false;
      } else {
        checkbox.checked = true;
      }
    });
  }
}
