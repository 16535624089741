import { Controller } from "@hotwired/stimulus";
import _ from "lodash";
import PolygonObject from "../lib/polygon_object";
/* global google */

export default class extends Controller {
  static values = {
    location: Object,
    childIndex: Number,
    appendFieldPath: String,
    polygons: Object,
  };

  static targets = ["map", "hiddenInput", "nameInput"];

  // Improved state management while keeping it in the controller
  polygonStates = new Map();

  listeners = [];

  activePolygon = null;

  pulseInterval = null;

  unsavedPolygon = null;

  inputPolygonNameFieldInfoWindow = null;

  // Lifecycle methods
  connect() {
    if (typeof google !== "undefined") {
      this.initializeMap();
    }
  }

  disconnect() {
    this.cleanupListeners();
    if (this.pulseInterval) {
      clearInterval(this.pulseInterval);
    }
  }

  initializeMap() {
    this.createMap();
    this.setupDrawingManager();
    this.initializeExistingPolygons();
  }

  initializeExistingPolygons() {
    if (!this.polygonsValue || !this.polygonsValue.features) return;

    this.polygonsValue.features.forEach((geoJsonPolygonFeature) => {
      const coordinates = geoJsonPolygonFeature.geometry.coordinates[0].map(
        (coord) => ({ lat: coord[1], lng: coord[0] }),
      );

      const polygonOnMap = new google.maps.Polygon({
        paths: coordinates,
        map: this.map,
        ...this.getDefaultPolygonOptions(),
      });

      const { name } = geoJsonPolygonFeature.properties;
      this.addPolygonToState(name, polygonOnMap);
      this.setupPolygonEventHandlers(polygonOnMap);
    });
  }

  createMap() {
    const center = this.getMapCenter();
    this.map = new google.maps.Map(this.mapTarget, {
      center,
      zoom: 7,
      streetViewControl: false,
    });
  }

  getMapCenter() {
    if (this.hasExistingPolygon()) {
      return this.getFirstPolygonCoordinate();
    }
    return this.locationValue;
  }

  hasExistingPolygon() {
    return this.polygon && this.polygon.coordinates.length !== 0;
  }

  getFirstPolygonCoordinate() {
    return this.polygon.coordinates[0];
  }

  setupDrawingManager() {
    this.drawingManager = new google.maps.drawing.DrawingManager({
      drawingControl: true,
      drawingControlOptions: this.getDrawingControlOptions(),
      polygonOptions: this.getDefaultPolygonOptions(),
    });

    this.drawingManager.setMap(this.map);
    this.setupPolygonCompleteListener();
  }

  getDrawingControlOptions() {
    return {
      position: google.maps.ControlPosition.TOP_CENTER,
      drawingModes: [
        google.maps.drawing.OverlayType.HAND,
        google.maps.drawing.OverlayType.POLYGON,
      ],
    };
  }

  getDefaultPolygonOptions() {
    return {
      editable: true,
      fillColor: "#ffffff",
      fillOpacity: 0.20,
      strokeWeight: 2,
      strokeColor: "#000000",
      zIndex: 1,
    };
  }

  setupPolygonCompleteListener() {
    this.addListener(
      this.drawingManager,
      "polygoncomplete",
      this.handlePolygonComplete.bind(this),
    );
  }

  handlePolygonComplete(polygon) {
    this.drawingManager.setDrawingMode(null);
    this.setupPolygonEventHandlers(polygon);
    this.attachInputPolygonNameField(polygon);
  }

  setupPolygonEventHandlers(polygon) {
    this.setupPolygonClickHandler(polygon);
    this.setupPolygonHoverHandlers(polygon);
    this.setupPolygonRightClickHandler(polygon);
  }

  setupPolygonClickHandler(polygon) {
    this.addListener(polygon, "click", () => {
      this.setActivePolygon(polygon);
      this.centerAndZoomToPolygon(polygon);
      this.highlightPolygon(polygon);
    });
  }

  setupPolygonHoverHandlers(polygon) {
    const defaultStyle = { ...this.getDefaultPolygonOptions() };
    const hoverStyle = {
      fillOpacity: 0.35,
      strokeWeight: 3,
      strokeColor: "#4A90E2",
    };

    this.addListener(polygon, "mouseover", () => {
      if (polygon !== this.activePolygon) {
        polygon.setOptions(hoverStyle);
      }
    });

    this.addListener(polygon, "mouseout", () => {
      if (polygon !== this.activePolygon) {
        polygon.setOptions(defaultStyle);
      }
    });
  }

  setupPolygonRightClickHandler(polygon) {
    this.addListener(polygon, "rightclick", () => {
      this.handlePolygonRightClick(polygon);
    });
  }

  handlePolygonRightClick(polygon) {
    const polygonName = this.findPolygonKey(polygon);
    const confirmMessage = polygonName
      ? `Delete ${polygonName}?`
      : "Delete unsaved polygon?";

    const confirmed = window.confirm(confirmMessage);
    if (confirmed) {
      this.deletePolygon(polygon);
    }
  }

  buildInfoWindowContent() {
    return `
      <table>
        <tr class="fl-input__row">
          <td>
            <input 
              type="text"
              class="fl-input"
              data-operation-region-creator-target="nameInput"
              data-action="keydown->operation-region-creator#handleKeydown"
            />
          </td>
        </tr>
      </table>
    `;
  }

  handleKeydown(event) {
    switch (event.key) {
      case "Enter":
        event.preventDefault();
        event.stopPropagation();
        this.handleEnterKey();
        break;
      case "Escape":
        event.preventDefault();
        event.stopPropagation();
        this.handleEscapeKey();
        break;
    }
  }

  handleEnterKey() {
    const polygonName = this.nameInputTarget.value;

    if (!this.validatePolygon(polygonName)) {
      this.nameInputTarget.focus();
      return;
    }

    this.initializePolygon(this.unsavedPolygon, polygonName);
    this.inputPolygonNameFieldInfoWindow?.close();
  }

  handleEscapeKey() {
    this.inputPolygonNameFieldInfoWindow?.close();
    this.unsavedPolygon?.setMap(null);
  }

  validatePolygon(polygonName) {
    if (!polygonName) {
      alert("The field cannot be empty, please provide a name!");
      return false;
    }

    if (this.polygonStates.has(polygonName)) {
      alert(`The name "${polygonName}" already exists, please provide a different name!`);
      return false;
    }

    return true;
  }

  attachInputPolygonNameField(polygon) {
    this.unsavedPolygon = polygon;
    const inputPolygonNameFieldInfoWindow = new google.maps.InfoWindow({
      content: this.buildInfoWindowContent(),
      position: this.calculatePolygonCenter(polygon),
    });

    this.inputPolygonNameFieldInfoWindow = inputPolygonNameFieldInfoWindow;
    inputPolygonNameFieldInfoWindow.open(this.map);

    google.maps.event.addListenerOnce(inputPolygonNameFieldInfoWindow, "domready", () => {
      const closeButtons = document.querySelectorAll(".gm-ui-hover-effect");
      closeButtons.forEach((button) => {
        button.style.display = "none";
      });
      this.nameInputTarget.focus();
    });
  }

  calculatePolygonCenter(polygon) {
    const coordinates = polygon.getPath().getArray();
    const bounds = new google.maps.LatLngBounds();
    coordinates.forEach((coordinate) => bounds.extend(coordinate));
    return bounds.getCenter();
  }

  setActivePolygon(polygon) {
    // Reset previous active polygon if exists
    if (this.activePolygon && this.activePolygon !== polygon) {
      this.activePolygon.setOptions(this.getDefaultPolygonOptions());
      if (this.pulseInterval) {
        clearInterval(this.pulseInterval);
      }
    }

    this.activePolygon = polygon;
    polygon.setEditable(true);
  }

  centerAndZoomToPolygon(polygon) {
    const bounds = new google.maps.LatLngBounds();
    polygon.getPath().forEach((coordinate) => bounds.extend(coordinate));

    const padded = this.addPaddingToBounds(bounds);
    this.map.panTo(padded.getCenter());

    setTimeout(() => {
      this.map.fitBounds(padded, {
        duration: 500,
        easing: "easeInOutCubic",
      });
    }, 300);
  }

  addPaddingToBounds(bounds) {
    const padded = new google.maps.LatLngBounds();
    const ne = bounds.getNorthEast();
    const sw = bounds.getSouthWest();

    const latPadding = (ne.lat() - sw.lat()) * 0.1;
    const lngPadding = (ne.lng() - sw.lng()) * 0.1;

    padded.extend(new google.maps.LatLng(ne.lat() + latPadding, ne.lng() + lngPadding));
    padded.extend(new google.maps.LatLng(sw.lat() - latPadding, sw.lng() - lngPadding));

    return padded;
  }

  highlightPolygon(polygon) {
    const highlightStyle = {
      fillColor: "#4A90E2",
      fillOpacity: 0.35,
      strokeWeight: 3,
      strokeColor: "#2171D1",
    };

    polygon.setOptions(highlightStyle);

    let opacity = 0.35;
    let increasing = false;

    if (this.pulseInterval) {
      clearInterval(this.pulseInterval);
    }

    this.pulseInterval = setInterval(() => {
      if (polygon !== this.activePolygon) {
        clearInterval(this.pulseInterval);
        return;
      }

      if (increasing) {
        opacity += 0.01;
        if (opacity >= 0.45) {
          increasing = false;
        }
      } else {
        opacity -= 0.01;
        if (opacity <= 0.25) {
          increasing = true;
        }
      }

      polygon.setOptions({
        ...highlightStyle,
        fillOpacity: opacity,
      });
    }, 50);
  }

  deletePolygon(polygon) {
    const key = this.findPolygonKey(polygon);
    if (key) {
      const polygonField = document.getElementById(key);
      this.polygonStates.delete(key);
      if (polygonField) {
        polygonField.remove();
      }
      polygon.setMap(null);
    }

    if (this.activePolygon === polygon) {
      this.activePolygon = null;
      if (this.pulseInterval) {
        clearInterval(this.pulseInterval);
      }
    }
  }

  findPolygonKey(polygon) {
    const invertedMap = new Map(
      [...this.polygonStates.entries()].map(([key, value]) => [value, key]),
    );
    return invertedMap.get(polygon.getPath());
  }

  initializePolygon(polygon, polygonName) {
    const coordinates = this.getPolygonCoordinates(polygon);
    this.savePolygon(polygonName, coordinates, polygon);
  }

  getPolygonCoordinates(polygon) {
    const coordinates = polygon.getPath().getArray();
    return _.map(coordinates, (latlng) => [latlng.lng(), latlng.lat()]);
  }

  savePolygon(name, coordinates, polygon) {
    this.appendPolygonFormField(name, new PolygonObject(coordinates).polygonString());
    this.addPolygonToState(name, polygon);
  }

  addPolygonToState(polygonName, polygon) {
    const polygonInstance = polygon.getPath();
    this.polygonStates.set(polygonName, polygonInstance);
    this.setupPolygonStateListeners(polygonName, polygonInstance);
  }

  setupPolygonStateListeners(polygonName, polygonInstance) {
    const callback = () => this.updatePolygonState(polygonName, polygonInstance);
    this.addListener(polygonInstance, "set_at", callback);
    this.addListener(polygonInstance, "insert_at", callback);
  }

  updatePolygonState(polygonName, polygonInstance) {
    const coordinates = this.getPolygonCoordinatesFromInstance(polygonInstance);
    this.updatePolygonField(polygonName, coordinates);
  }

  getPolygonCoordinatesFromInstance(polygonInstance) {
    const googlePolyArray = polygonInstance.getArray();
    return _.map(googlePolyArray, (latlng) => [latlng.lng(), latlng.lat()]);
  }

  updatePolygonField(polygonName, coordinates) {
    const element = document.getElementById(`${polygonName.toLowerCase()}-polygon`);
    if (element) {
      element.value = new PolygonObject(coordinates).polygonString();
    }
  }

  addListener(instance, event, callback) {
    const listener = google.maps.event.addListener(instance, event, callback);
    this.listeners.push(listener);
    return listener;
  }

  cleanupListeners() {
    this.listeners = this.listeners.filter((listener) => {
      listener.remove();
      return false;
    });
  }

  getCsrfToken() {
    return document.querySelector("meta[name=\"csrf-token\"]").content;
  }

  async appendPolygonFormField(name, polygonObject) {
    const formData = this.createFormData(name, polygonObject);
    const response = await this.sendFormData(formData);
    const html = await response.text();
    Turbo.renderStreamMessage(html);
  }

  createFormData(name, polygonObject) {
    const formData = new FormData();
    formData.set("slug", name);
    formData.set("boundary", polygonObject);
    formData.set("child_index", this.childIndexValue++);
    return formData;
  }

  async sendFormData(formData) {
    const path = `${window.location.origin}${this.appendFieldPathValue}`;
    return fetch(path, {
      method: "POST",
      headers: {
        Accept: "text/vnd.turbo-stream.html",
        "X-CSRF-Token": this.getCsrfToken(),
      },
      body: formData,
    });
  }
}
