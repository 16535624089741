import { Controller } from "@hotwired/stimulus";
/* global google */

export default class extends Controller {
  static values = {
    googleMapsApiKey: String,
    location: Object,
    polygons: Object,
  };

  static targets = ["map"];

  initializeMap() {
    const center = this.locationValue;
    const polygons = this.polygonsValue;

    this.map = new google.maps.Map(this.mapTarget, {
      center: center,
      zoom: 10,
      streetViewControl: false,
    });
    this.map.data.addGeoJson(polygons);
    this.map.data.setStyle((feature) => ({
      fillColor: feature.getProperty("color"),
      strokeWeight: 1
    }));
  }

  connect() {
    if (typeof (google) != "undefined"){
      this.initializeMap();
    }
  }
}
