import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["filter"]
  static values = {
    fps: { type: Number, default: 30 },
    speed: { type: Number, default: 0.8 }
  }

  initialize() {
    // Pre-calculate values
    this.rad = Math.PI / 180;
    this.frames = 0;
    this.animationFrameId = null;

    // Throttle the animation frame rate
    this.fpsInterval = 1000 / this.fpsValue;
    this.then = Date.now();
  }

  connect() {
    this.startAnimation();
  }

  disconnect() {
    this.stopAnimation();
  }

  startAnimation() {
    const animate = () => {
      this.animationFrameId = requestAnimationFrame(animate);

      // Throttle frame rate
      const now = Date.now();
      const elapsed = now - this.then;

      if (elapsed > this.fpsInterval) {
        this.then = now - (elapsed % this.fpsInterval);

        // Update animation
        this.frames += this.speedValue;

        // Calculate frequencies
        const bfx = 0.005 + 0.002 * Math.cos(this.frames * this.rad);
        const bfy = 0.005 + 0.002 * Math.sin(this.frames * this.rad);
        const bf = `${bfx} ${bfy}`;

        this.filterTarget.setAttributeNS(null, 'baseFrequency', bf);
      }
    };

    this.animationFrameId = requestAnimationFrame(animate);
  }

  stopAnimation() {
    if (this.animationFrameId) {
      cancelAnimationFrame(this.animationFrameId);
      this.animationFrameId = null;
    }
  }
}
