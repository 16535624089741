import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["switch", "submit"];

  connect() {
    this.switches = new Map();
    this.updateSwitchStates();
  }

  updateSwitchStates() {
    this.switchTargets.forEach((switchElement) => {
      this.switches.set(switchElement.name, switchElement.checked);
    });
    this.updateSubmitButton();
  }

  toggle(event) {
    const switchElement = event.target;
    this.switches.set(switchElement.name, switchElement.checked);
    this.updateSubmitButton();
  }

  updateSubmitButton() {
    const hasCheckedSwitch = Array.from(this.switches.values()).some((checked) => checked);
    this.submitTarget.classList.toggle("is-disabled", !hasCheckedSwitch);
  }
}
