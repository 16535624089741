import { Controller } from "@hotwired/stimulus"
import { post } from "@rails/request.js"

export default class extends Controller {
  static values = {
    url: String,
    timeout: Number,
  }

  connect() {
    this.remainingTime = this.timeoutValue
    this.startTimer()
  }

  startTimer() {
    this.timer = setTimeout(() => {
      this.declineBooking()
    }, this.timeoutValue * 1000)
  }

  declineBooking() {
    post(this.urlValue, {
      headers: { "Content-Type": "application/json", "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content },
    }).then(response => {
      if (response.ok) {
        // Reload or update the UI to reflect the decline
        // location.reload()
      } else {
        console.error("Failed to decline booking request")
      }
    }).catch(error => {
      console.error("Error:", error)
    })
  }

  disconnect() {
    clearTimeout(this.timer)
  }
}
