import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["checkbox", "submit"];

  connect() {
    this.toggleSubmitButton();
  }

  toggleSubmitButton() {
    if (this.checkboxTarget.checked) {
      this.submitTarget.classList.remove("is-disabled");
    } else {
      this.submitTarget.classList.add("is-disabled");
    }
  }
}
